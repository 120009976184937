export const projectList = [
    {
      "id": 1,
      "name": "Tan An Villa",
      "countDetail": 12,
      "category": 'housing',
      "area": 450,
      "year": "2021",
      "region": "Hue, Viet Nam"
    },
    {
      "id": 2,
      "name": "Na House",
      "countDetail": 10,
      "category": 'housing',
      "area": 500,
      "year": "2022",
      "region": "Hue, Viet Nam"
    },
    {
      "id": 3,
      "name": "HT House",
      "countDetail": 7,
      "category": 'housing',
      "area": 450,
      "year": "2023",
      "region": "Hue, Viet Nam"
    },
    {
      "id": 4,
      "name": "Sline Billiards",
      "countDetail": 15,
      "category": 'public-work',
      "area": 540,
      "year": "2022",
      "region": "Hue, Viet Nam"
    },
    {
      "id": 5,
      "name": "Kelly Cafe",
      "countDetail": 8,
      "category": 'public-work',
      "area": 330,
      "year": "2022",
      "region": "Hue, Viet Nam"
    },
    {
      "id": 6,
      "name": "Lux City 02 Hotel",
      "countDetail": 9,
      "category": 'public-work',
      "area": '2.500',
      "year": "2022",
      "region": "Phnom Penh, Cambodia"
    },
    {
      "id": 7,
      "name": "Skybar Rooftop Luxcity 2",
      "countDetail": 8,
      "category": 'landscape',
      "area": 600,
      "year": "2023",
      "region": "Phnom Penh, Cambodia"
    },
    {
      "id": 8,
      "name": "15F BUILDING",
      "countDetail": 8,
      "category": 'housing',
      "area": '25.000',
      "year": "2022",
      "region": "Bavet, Cambodia"
    },
    {
      "id": 9,
      "name": "Phuoc Hiep Blessed Virgin Abbey",
      "countDetail": 8,
      "category": 'religious-work',
      "area": '10.000',
      "year": "2022",
      "region": "Ba Ria, Viet Nam"
    },
    {
      "id": 10,
      "name": "Sline the Manor Cafe",
      "countDetail": 7,
      "category": 'interior',
      "area": '150',
      "year": "2021",
      "region": "Hue, Viet Nam"
    },
  ]