import React from 'react';
import Iframe from 'react-iframe'

function Contact() {
    return (
        <div className="contact mt-sm-5">
            <div className="contact-container container">
                <div className="row">
                    <div className="col-sm-8 container-map p-0">
                        <Iframe url="https://www.google.com/maps/embed/v1/place?q=139%20L%C3%BD%20Ch%C3%ADnh%20Th%E1%BA%AFng%2C%20V%C3%B5%20Th%E1%BB%8B%20S%C3%A1u%2C%20Qu%E1%BA%ADn%203%2C%20Th%C3%A0nh%20ph%E1%BB%91%20H%E1%BB%93%20Ch%C3%AD%20Minh%2C%20Vi%E1%BB%87t%20Nam&key=AIzaSyDc7PnOq3Hxzq6dxeUVaY8WGLHIePl0swY"
                            width="100%"
                            height="100%"
                            id=""
                            className="iframe"
                            display="block"
                            position="relative"
                            allowFullScreen/>
                    </div>
                    <div className="col-sm contact-content p-sm-5 p-3 mb-5 mb-sm-0">
                        <h2 className='mb-2 mb-sm-5 contact-title d-none d-sm-block'>Contact us</h2>
                        <p><i className="contact-icon fas fa-map-marker-alt mr-2"></i>139 Ly Chinh Thang st, District 3 <br/> Ho Chi Minh City<span className='d-sm-none'>, Viet Nam</span></p>
                        <p className='d-none d-sm-block'><i className="contact-icon fas fa-globe"></i> Country: Viet Nam</p>
                        <p><i className="contact-icon fas fa-phone"></i> +84 901 184 175</p>
                        <p><i className="contact-icon far fa-envelope"></i> 2020studio.saigon@gmail.com</p>
                    </div>
                </div>
            
            </div>
        </div>
    );
}

export default Contact;
